import { Box, Card, Container, Typography } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import { MHidden } from '../components/@material-extend';
import { RegisterForm } from '../components/authentication/register';
// components
import Page from '../components/Page';
// layouts
import AuthLayout from '../layouts/AuthLayout';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 630,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(2, 0, 2, 0)
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: '10vh auto',
  display: 'flex',
  minHeight: '77vh',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(12, 0),
  [theme.breakpoints.down('md')]: {
    maxWidth: 355,
    margin: '8vh auto 0'
  }
}));

const BoxStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'md')]: {
    textAlign: 'center',
    padding: 15
  }
}));

// ----------------------------------------------------------------------

export default function Register() {
  return (
    <RootStyle title="Register | SistemaNube">
      <AuthLayout />

      <MHidden width="xsDown">
        <SectionStyle>
          <ContentStyle>
            <BoxStyle sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Creá tu Sistema Nube
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Aprovechá <b>14 días de prueba gratis</b> para hacer crecer tus ventas fidelizando
                clientes
              </Typography>
            </BoxStyle>

            <RegisterForm />
          </ContentStyle>
        </SectionStyle>
      </MHidden>

      <Container />
    </RootStyle>
  );
}
