import * as Yup from 'yup';

export const registerSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Demasiado corto!')
    .max(100, 'Demasiado largo!')
    .required('El nombre de tu negocio es requerido'),
  email: Yup.string().email('Formato de email inválido').required('Email es requerido'),
  password: Yup.string().required('Contraseña es requerida')
});
