import axios from 'axios';
import { useFormik } from 'formik';
import swal from 'sweetalert';
import { slugString } from '../../../utils/formatUrl';
import { registerSchema } from './register.schema';

export const showGeneralError = (errorMessage) =>
  swal({
    title: errorMessage ? undefined : 'Ups...',
    text: errorMessage || 'Ha ocurrido un error',
    icon: 'error',
    buttons: [false, 'OK']
  });

const handleApiError = (err) => {
  const httpError = err.response;
  if (httpError) {
    const { errors } = httpError.data;
    if (errors && Object.keys(errors).length > 0) {
      const errorMesages = [];
      Object.keys(errors).forEach((key) => {
        errorMesages.push(errors[key][0]);
      });
      showGeneralError(errorMesages.join('\n'));
    } else {
      console.log(httpError);
      showGeneralError();
    }
  } else {
    console.log(httpError);
    showGeneralError();
  }
};

export const useRegisterFormik = () => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: ''
    },
    validationSchema: registerSchema,
    onSubmit: (values, formikHelpers) => {
      const { setSubmitting } = formikHelpers;
      const { name, email, password } = values;
      const queryParams = new URLSearchParams(window.location.search);
      const storeId = queryParams.get('store_id') ?? undefined;
      const listaSistemaNubeId = 8;
      const listaTiendaNubeId = 7;

      const data = {
        club: { name, store_id: storeId },
        admin: {
          email,
          password
        }
      };

      setSubmitting(true);

      axios
        .post(`${process.env.REACT_APP_BASE_URL_API}/v1/clubs/register`, data, {
          headers: {
            'x-transaction-origin': 'cloud-system-register'
          }
        })
        .then(() => {
          axios.post(
            'https://api.brevo.com/v3/contacts',
            {
              email: data.admin.email,
              listIds: [storeId ? listaTiendaNubeId : listaSistemaNubeId]
            },
            {
              headers: {
                'api-key': process.env.REACT_APP_BREVO_API_KEY,
                'Content-Type': 'application/json'
              }
            }
          );
        })
        .then(() => {
          setSubmitting(false);
          const urlPanel = process.env.REACT_APP_BASE_URL_PANEL.replace(
            '{subdomain}',
            slugString(name)
          );
          window.location.href = `${urlPanel}/login`;
        })
        .catch((err) => {
          handleApiError(err);
          setSubmitting(false);
        });
    }
  });

  return formik;
};
